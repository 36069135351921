<template>
  <button 
    class="vcard__create" 
    @click="$router.push({ name: 'vcard-create' })"
    >
    Crear Vcard
  </button>
</template>

<script>
export default {
    name: 'createVcardButton'
}
</script>

<style>

</style>
